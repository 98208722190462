<template>
  <cui-acl :roles="['signatureManual']" redirect v-if="loaded">
    <a-form @submit.prevent="generateContract()">
      <h2>Sky Portal | eSign</h2>
      <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <a-form-item label="Kontraktsnavn">
            <a-input placeholder="Kontraktsnavn" v-model="data.title"/>
            </a-form-item>
          </div>
          <div class="col-md-6">
            <a-form-item label="Selskap" :required="true">
              <a-select
                ref="select"
                v-model="data.company"
                :options="companies"
                style="width: 300px"
              ></a-select>
            </a-form-item>
          </div>
          <div class="col-md-6">
            <a-form-item label="Signeringsmetode" :required="true">
              <a-select
                mode="multiple"
                style="width: 100%"
                v-model="data.signatureMethods"
                :options="signatureMethods"
              >
              </a-select>
            </a-form-item>
          </div>
        </div>
      </div>
      </div>
      <div class="card">
      <div class="card-body">
        <h5 class="text-black mt-2 mb-3">Mottakere</h5>
        <div class="card" v-for="(signer, index) in data.signers" :key="index">
        <div class="card-body">
          <p class="text-black mt-2 mb-3">Mottaker {{ index + 1 }}</p>
          <div class="row">
            <div class="col-lg-6">
              <a-form-item :label="$t('userInfo.name')" :required="true">
              <a-input :placeholder="$t('userInfo.name')" v-model.trim="data.signers[index].name"/>
              </a-form-item>
            </div>
            <div class="col-lg-6">
              <a-form-item :label="$t('userInfo.surname')" :required="true">
              <a-input :placeholder="$t('userInfo.surname')" v-model.trim="data.signers[index].surname"/>
              </a-form-item>
            </div>
            <div class="col-lg-6">
              <a-form-item :label="$t('userInfo.email')" :required="true">
              <a-input :placeholder="$t('userInfo.email')" v-model.trim="data.signers[index].email"/>
              </a-form-item>
            </div>
            <div class="col-lg-6">
              <a-form-item :label="$t('userInfo.phone')" :required="true">
              <a-input :placeholder="$t('userInfo.phone')" v-model.trim="data.signers[index].phone"/>
              </a-form-item>
            </div>
          </div>
          <a-button @click.prevent="removeSigner(index)">Slett mottaker</a-button>
        </div>
        </div>
        <a-button @click.prevent="addSigner">Legg til mottaker</a-button>
      </div>
      </div>
      <div class="card">
      <div class="card-body">
        <h5 class="text-black mt-2 mb-3">Last opp avtale</h5>
      </div>
      </div>
      <div class="card" v-if="['signatureNoConfirmation'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
      <div class="card-body">
        <h5 class="text-black mt-2 mb-3">Avansert</h5>
        <div class="col-md-6">
          <a-form-item label="Skal">
          <a-radio-group v-model="sendDirectly">
              <a-radio :style="radioStyle" :value="true">
              Ja
              </a-radio>
              <a-radio :style="radioStyle" :value="false">
              Nei
              </a-radio>
          </a-radio-group>
          </a-form-item>
        </div>
      </div>
      </div>
      <div class="form-actions mt-10">
      <a-button style="width: 150px;" type="primary" htmlType="submit" class="mr-3" :loading="loading">Onboard Ansatt</a-button>
      <!-- <a-button style="width: 150px;" type="error" class="mr-3" @click="resetForm">Reset</a-button> -->
      </div>
    </a-form>
  </cui-acl>
</template>

<script>
import cuiAcl from '@/components/cleanui/system/ACL'
import moment from 'moment'
import validator from 'norsk-validator'
import { firestore } from '@/services/firebase'

const companies = [
  {
    name: 'Skycall',
    value: 'Skycall',
    label: 'Skycall',
    phone: '+4741860278',
    email: 'info@skycallas.no',
    url: 'https://skycallas.no',
  },
  {
    name: 'Skygruppen',
    value: 'Skygruppen',
    label: 'Skygruppen',
    phone: '+4741860278',
    email: 'info@skygruppen.no',
    url: 'https://skygruppen.no',
  },
  {
    name: 'Sky Estate',
    value: 'Sky Estate',
    label: 'Sky Estate',
    phone: '+4741860278',
    email: 'info@skyestate.no',
    url: 'https://skyestate.no',
  },
  {
    name: 'Sky Marketing',
    value: 'Sky Marketing',
    label: 'Sky Marketing',
    phone: '+4741860278',
    email: 'kontakt@skymarketing.no',
    url: 'https://skymarketing.no',
  },
]

const signatureMethods = [
  {
    value: 'sms_otp',
    label: 'SMS bekreftelse',
  },
  {
    value: 'no_bankid_netcentric',
    label: 'Norsk Bank ID',
  },
]

export default {
  components: {
    cuiAcl,
  },
  data() {
    return {
      positions: [{ type: null }],
      loading: false,
      fnrValid: false,
      fnrError: false,
      bankValid: false,
      bankError: false,
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      data: {
        company: '',
        title: '',
        signers: [],
      },
      file: {},
      companies,
      signatureMethods,
      loaded: false,
      sendDirectly: false,
    }
  },
  methods: {
    moment,
    async generateContract() {
      const data = this.data
      const date = String(moment().format('YYYY-MM-DD'))
      // const fileName = data.title.concat('.pdf')
      const contract = this.file
      firestore.collection('signatures').add({
        status: 'under approval',
        files: [
          {
            fileName: contract.name,
            filePath: contract.filePath,
            url: contract.url,
            type: 'unsigned',
          },
        ],
        title: contract.name,
        sendtBy: this.user.id,
        documentType: 'manual-contract',
        department: data.department,
        positionIndex: 0,
        project: data.positions[0].project,
        signed: false,
        signatureMethods: ['sms_otp'],
        signers: [
          {
            name: data.name,
            surname: data.surname,
            email: data.email,
            phone: data.phone,
            employeeID: data.employeeID,
            id: data.employeeID,
          },
        ],
        company: this.company,
        createdOn: date,
      })
    },
    fnrValidate() {
      let data = this.data.personalNumber
      data = data.replace(/[\s.]/g, '')
      this.data.personalNumber = data
      if (data.length === 11) {
        if (validator.fodselsnummer(this.data.personalNumber)) {
          this.calculateBirthday(data)
          this.fnrError = false
          this.fnrValid = true
        } else {
          this.fnrValid = false
          this.fnrError = true
        }
      } else {
        this.fnrValid = false
        this.fnrError = true
      }
    },
    bankValidate() {
      let data = this.data.bankAccount
      data = data.replace(/[\s.]/g, '')
      this.data.bankAccount = data
      if (data.length === 11) {
        if (validator.kontonummer(data)) {
          this.bankError = false
          this.bankValid = true
        } else {
          this.bankValid = false
          this.bankError = true
        }
      } else {
        this.bankValid = false
        this.bankError = true
      }
    },
    addSigner() {
      this.data.signers.push({})
    },
    removeSigner(i) {
      this.data.signers.splice(i, 1)
    },
  },
  mounted() {
    this.loaded = true
  },
}
</script>

<style scoped>
  .validfield {
    border-color: green!important
  }
  .invalidfield {
    border-color: red!important
  }
</style>
